import merge from 'lodash/merge';
import get from 'lodash/get';

// eslint-disable-next-line default-param-last
function processMockConfigurations(mockConfig = {}, serviceMocks) {
    const { services = {}, ...rest } = mockConfig;

    return {
        services: merge({}, serviceMocks, services),
        ...rest,
    };
}

// eslint-disable-next-line default-param-last
export default (servicesConfig = {}, serviceMocks) => ({
    mock: processMockConfigurations(servicesConfig.mock, serviceMocks),
});

export const createServiceConfiguration = config => ({
    global: get(config, 'global', {}),
});
