import image from '../assets/images/ai-logo.jpg';

const initialDbData = {
    tenants: [
        {
            tenantCode: 'ainq',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
            base: { friendlyName: 'AINQ' },
            general: {},
            security: {
                preventUserFromUsingPreviousPasswords: false,
                passwordMaximumAgeInDays: 365,
                passwordMinNumbers: 1,
                allowManualUserCreation: true,
                durationToRetainAuditLogsInYears: 3,
                enableLockout: true,
                graceLogins: 5,
                lockoutDurationInMinutes: 300,
                passwordExpire: true,
                passwordHistoryCount: 0,
                passwordMinAlphaNumeric: 7,
                passwordMinLowerCase: 1,
                passwordMinSymbols: 1,
                passwordMinUpper: 1,
                passwordMinimumLength: 7,
                passwordResetLinkExpInMinutes: 60,
                passwordWarningTimeInDays: 14,
                securityQuestionsToBeAnswered: 1,
                sessionTimeoutInMinutes: 45,
                auditRetention: 1,
            },
            sso: {
                enabledSso: true,
            },
            localization: {},
            branding: {
                headerLogoUrl: image,
                footerLogoUrl: image,
                loginLogoUrl: image,
            },
            agreements: [
                {
                    id: 'eula',
                    title: 'End User License Agreement (EULA)',
                    text: `<p class="p1" style="margin: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Times;"><span class="s1" style="font-kerning: none;"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu ultrices ipsum, eu sollicitudin nisl. Integer tincidunt velit nulla, hendrerit pretium neque ornare id. Curabitur commodo pellentesque sapien, at maximus metus tincidunt ut. Nam nec lectus diam. In eu hendrerit metus, vel lobortis ligula. Integer eu felis purus. Maecenas sapien ligula, egestas sed sem in, pharetra sodales magna. Ut interdum purus lacus, vitae congue neque feugiat non. Pellentesque vel augue sed sapien volutpat placerat.</span></p>
                    <p class="p2" style="margin: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Times; min-height: 14px;">&nbsp;</p>
                    <p class="p1" style="margin: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Times;"><span class="s1" style="font-kerning: none;"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>Vivamus accumsan sem ut ante efficitur, ac rhoncus turpis ultricies. Mauris a neque ut quam suscipit tempus. In laoreet, ligula sed feugiat ullamcorper, risus libero convallis velit, et fermentum purus felis nec est. Nulla non mauris quis nibh imperdiet pulvinar. Morbi euismod tortor sit amet blandit condimentum. Phasellus feugiat molestie urna in pellentesque. Phasellus volutpat eleifend turpis, eu auctor erat sodales et. Maecenas nec venenatis dolor. Suspendisse dictum odio at lorem iaculis consectetur. Nunc luctus elit non nisl congue, sed tincidunt turpis tincidunt. Nam cursus elementum posuere. Proin rutrum velit quis arcu pharetra imperdiet. Phasellus pharetra sem in vestibulum eleifend. Maecenas ac semper dui. Vestibulum ornare ex vitae leo tempus, et pulvinar justo tristique.</span></p>
                    <p class="p2" style="margin: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Times; min-height: 14px;">&nbsp;</p>
                    <p class="p1" style="margin: 0px; font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Times;"><span class="s1" style="font-kerning: none;"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>Etiam convallis elit magna, id scelerisque metus consectetur sit amet. Nam tempus metus ac ullamcorper mollis. Nunc euismod mauris a augue tempor rutrum. Suspendisse in eleifend nunc, non ullamcorper mauris. Praesent consequat imperdiet laoreet. Suspendisse eu elit ac erat ornare tincidunt. Curabitur lectus leo, sagittis in magna vitae, venenatis porta eros. Integer commodo velit tellus, a pretium neque porttitor non. Cras id egestas arcu, a porttitor lacus. Donec porttitor metus ante, ut lacinia nisi interdum sed. Suspendisse bibendum ligula cursus, facilisis nunc sit amet, efficitur arcu.</span></p>`,
                },
            ],
        },
        {
            tenantCode: 'foo',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9_foo',
            base: { friendlyName: 'Tenant Foo' },
            general: {},
            security: {},
            sso: {},
            localization: {},
            branding: {
                headerLogoUrl: image,
                footerLogoUrl: image,
                loginLogoUrl: image,
            },
        },
        {
            tenantCode: 'bar',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9_bar',
            base: { friendlyName: 'Tenant Bar' },
            general: {},
            security: {},
            sso: {},
            localization: {},
            branding: {
                headerLogoUrl: image,
                footerLogoUrl: image,
                loginLogoUrl: image,
            },
        },
    ],
    pulse: [
        {
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
            locations: [
                {
                    createdByName: 'Neil Harris',
                    locationCode: '607429',
                    locationName: 'LOC 607429',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    created: '2020-05-22T19:48:38.958Z',
                    modifiedByName: 'Neil Harris',
                    locationId: '0e67f349-887b-47e5-aa50-bb297fbb89aa',
                    createdBy: '',
                    modified: '2020-05-22T19:48:38.958Z',
                    modifiedBy: '',
                },
                {
                    locationCode: 'LOCODE23',
                    locationName: 'West Hollywood Location',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    modifiedByName: 'John N/A',
                    comments: "Ben's comment",
                    locationId: '45d4ccd6-13d7-4ec7-9a31-bcc381659d92',
                    modified: '2020-05-22T20:16:26.056Z',
                    modifiedBy: 'sysadmin',
                },
                {
                    locationCode: 'LOCODE9002',
                    locationId: '48a494cc-ce2b-4859-a41f-c478262dbf4d',
                    locationName: 'Location LOCODE9002',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                },
            ],
            facilities: [
                {
                    createdByName: 'New Name User',
                    accessedBy: 'demo',
                    facilityCode: '222705',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    created: '2020-05-18T15:46:48.899Z',
                    comments: "Fred's comment",
                    accessedByName: 'Neil Patrick Harris',
                    address: '123 Fred Blvd',
                    email: 'fred@test.com',
                    createdBy: '438083@gmail.com',
                    accessed: '2020-05-21T19:59:31.675Z',
                    active: true,
                    facilityName: 'Facility 222705',
                    modifiedByName: 'John N/A',
                    pointOfContact: 'Fred Fredrickson',
                    locationId: '0e67f349-887b-47e5-aa50-bb297fbb89aa',
                    facilityId: 'a1670ee6-6076-4e2e-8c89-6a76aa6e6ba6',
                    phone: 1234567890,
                    modified: '2020-05-18T15:57:48.118Z',
                    modifiedBy: 'sysadmin',
                },
                {
                    accessed: '2020-05-21T15:21:56.613Z',
                    accessedBy: 'demo',
                    active: true,
                    facilityCode: 'FACCODE102',
                    facilityName: 'Yost Facility',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    modifiedByName: 'John N/A',
                    locationId: '0e67f349-887b-47e5-aa50-bb297fbb89aa',
                    accessedByName: 'Neil Patrick Harris',
                    facilityId: '457055d8-29ee-4d7f-aec5-53255e41fb74',
                    modified: '2020-05-20T21:33:28.054Z',
                    modifiedBy: 'sysadmin',
                },
                {
                    accessed: '2020-05-21T13:51:52.248Z',
                    accessedBy: 'demo',
                    active: true,
                    facilityCode: 'FACCODE101',
                    facilityName: 'Hollywood Facility',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    modifiedByName: 'John N/A',
                    locationId: '0e67f349-887b-47e5-aa50-bb297fbb89aa',
                    accessedByName: 'Neil Patrick Harris',
                    facilityId: 'bcb7975f-5822-491b-836f-697b5d63dd2d',
                    modified: '2020-05-20T21:34:38.554Z',
                    modifiedBy: 'sysadmin',
                },
                {
                    accessedBy: 'demo',
                    facilityCode: 'FACCODE100',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                    comments: "Fred's comment",
                    accessedByName: 'Neil Patrick Harris',
                    address: '123 Fred Blvd',
                    email: 'fred@test.com',
                    accessed: '2020-05-21T19:59:23.514Z',
                    active: false,
                    facilityName: 'East Hollywood Location',
                    modifiedByName: 'John N/A',
                    pointOfContact: 'Fred Fredrickson',
                    locationId: '0e67f349-887b-47e5-aa50-bb297fbb89aa',
                    facilityId: 'de1e6b4f-b549-41d6-860c-fadb4b5593c1',
                    phone: 1234567890,
                    modified: '2020-05-21T20:37:06.824Z',
                    modifiedBy: 'sysadmin',
                },
                {
                    facilityCode: 'FACCODE8',
                    locationId: '48a494cc-ce2b-4859-a41f-c478262dbf4d',
                    facilityId: '0f108d13-a1f5-4a88-97a9-6c063b96e9a6',
                    facilityName: 'East Main Facility',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                },
                {
                    facilityCode: 'FACCODE7',
                    locationId: '48a494cc-ce2b-4859-a41f-c478262dbf4d',
                    facilityId: '42321711-3e8f-4c5e-a51f-0d9f80a4bba3',
                    facilityName: 'East Main Facility',
                    tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
                },
            ],
            system: {
                exchangesSupported: ['cten'],
                documentTypes: [
                    'episode-summary',
                    'discharge-summary',
                    'referral-note',
                    'progress-note',
                    'care-note',
                    'care-plan',
                ],
                patientCacheLifetime: 48,
            },
            sources: [
                {
                    dirInternalIdentifier: '1',
                },
            ],
        },
        {
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9_foo',
            system: {},
        },
        {
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9_bar',
            system: {},
        },
    ],
    sources: [
        {
            internalIdentifier: '1',
            entityName: 'Foo',
            addresses: [
                {
                    state: 'NC',
                },
            ],
        },
        {
            internalIdentifier: '2',
            entityName: 'Bar',
            addresses: [
                {
                    state: 'MD',
                },
            ],
        },
        {
            internalIdentifier: '3',
            entityName: 'Baz',
            addresses: [
                {
                    state: 'CA',
                },
            ],
        },
    ],
};

export default initialDbData;
