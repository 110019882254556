import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import { connectToStores } from 'fluxible-addons-react';
import { AuthenticatedPage } from '@audacious/client';

export default moduleDefinitions => {
    function processModules(session, subscriptionIds) {
        const menuOptions = [];
        const routes = [];
        let initialRoute = null;

        forEach(moduleDefinitions, moduleDefinition => {
            const {
                id: moduleId,
                name: moduleName,
                restrictions: moduleRestrictions,
                appRestrictions,
                sections,
                Page: ModulePage,
                icon,
            } = moduleDefinition;

            if (isArray(appRestrictions)) {
                const found = find(
                    appRestrictions,
                    restriction => !isNil(subscriptionIds[restriction]),
                );

                if (isNil(found)) {
                    return;
                }
            } else if (
                !isNil(appRestrictions) &&
                isNil(subscriptionIds[appRestrictions])
            ) {
                return;
            }

            const moduleAuthorized = session.isAuthorized(moduleRestrictions);

            if (!moduleAuthorized) {
                return;
            }

            const subMenuOptions = [];

            const firstLevelMenu = {
                id: moduleId,
                icon,
                label: moduleName,
                menuOptions: subMenuOptions,
            };

            if (isEmpty(sections)) {
                initialRoute = isNil(initialRoute) ? moduleId : initialRoute;

                const moduleRoute = `/${moduleId}`;

                routes.push(
                    <Route
                        key={moduleRoute}
                        path={moduleRoute}
                        component={ModulePage}
                    />,
                );
            } else {
                forEach(sections, section => {
                    const {
                        id: sectionId,
                        name: sectionName,
                        restrictions: sectionRestrictions,
                        Page: SectionPage,
                    } = section;

                    const sectionAuthorized = session.isAuthorized(
                        sectionRestrictions,
                    );

                    if (!sectionAuthorized) {
                        return;
                    }

                    const sectionRoute = `/${moduleId}/${sectionId}`;

                    initialRoute = isNil(initialRoute)
                        ? sectionRoute
                        : initialRoute;

                    subMenuOptions.push({
                        id: sectionId,
                        label: sectionName,
                    });

                    routes.push(
                        <Route
                            key={sectionRoute}
                            path={sectionRoute}
                            component={SectionPage}
                        />,
                    );
                });
            }

            if (!isEmpty(routes)) {
                menuOptions.push(firstLevelMenu);
            }
        });

        return {
            initialRoute,
            menuOptions,
            routes,
        };
    }

    function Application(props) {
        const { session, subscriptionIds } = props;

        const { initialRoute, menuOptions, routes } = useMemo(
            () => processModules(session, subscriptionIds),
            [session],
        );

        if (isNil(initialRoute)) {
            return <div>You have access to no modules</div>;
        }

        return (
            <AuthenticatedPage appMenuOptions={menuOptions}>
                <Switch>
                    {routes}
                    <Route path="*">
                        <Redirect to={initialRoute} />
                    </Route>
                </Switch>
            </AuthenticatedPage>
        );
    }

    Application.propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        session: PropTypes.object.isRequired,
        subscriptionIds: PropTypes.objectOf(PropTypes.string).isRequired,
    };
    Application.defaultProps = {};

    return connectToStores(Application, ['Session'], context => {
        const sessionStore = context.getStore('Session');
        const contextStore = context.getStore('ApplicationContext');

        const subscriptionData = contextStore
            .getTenantSubscriptions()
            .getData();

        const subscriptionIds = reduce(
            subscriptionData,
            (acc, subscriptionItem) => {
                acc[subscriptionItem.applicationId] = subscriptionItem;

                return acc;
            },
        );

        return {
            session: sessionStore.getSession(),
            subscriptionIds,
        };
    });
};
