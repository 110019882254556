import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import some from 'lodash/some';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Pendo from '@audacious/fluxible-pendo';
import { Client } from '@audacious/client';
import { createApplicationContext, createContextConfiguration } from './config';
import application from './components/application';

function processSections(sections) {
    return reduce(
        sections,
        (acc, section) => {
            const { name, id, Page } = section;

            if (!isString(name) || name.length <= 0) {
                throw new Error('Section name cannot be empty');
            }

            if (!isString(id) || id.length <= 0) {
                throw new Error('Section id cannot be empty');
            }

            if (some(acc, sectionDef => sectionDef.id === id)) {
                throw new Error('Section id must be unique within module');
            }

            if (isNil(Page)) {
                throw new Error('A section must contain a Page component');
            }

            acc.push({ ...section });

            return acc;
        },
        [],
    );
}

function processModuleConfiguration(module) {
    if (isNil(module)) {
        throw new Error('Module cannot be nil.');
    }

    const { name, id, Page, sections } = module;

    if (!isString(name) || name.length <= 0) {
        throw new Error('Module name cannot be empty');
    }

    if (!isString(id) || id.length <= 0) {
        throw new Error('Module id cannot be empty');
    }

    if (isEmpty(sections) && isNil(Page)) {
        throw new Error(
            'A module with no sections must contain a Page component',
        );
    }

    return {
        ...module,
        sections: processSections(sections),
    };
}

class AdminPortal {
    constructor(modules, config) {
        const moduleDefinitions = [];
        this.serviceMocks = {};

        const appStores = [];
        const appServices = [];

        forEach(modules, module => {
            const { stores, services } = module;

            const moduleDefinition = processModuleConfiguration(module);

            moduleDefinitions.push(moduleDefinition);

            if (isArray(stores)) {
                appStores.push(...stores);
            }

            if (isArray(services)) {
                forEach(services, service => {
                    appServices.push(service.service);

                    this.serviceMocks[service.id] = service.mock;
                });
            }
        });

        const Application = application(moduleDefinitions);

        this.app = new Client({
            components: {
                Application,
            },
            stores: appStores,
            context: createApplicationContext(get(config, 'context', {})),
            services: {
                serviceConfigs: appServices,
            },
        });

        this.app.plug(Pendo);
    }

    createContext(config) {
        const contextConfig = createContextConfiguration(
            config,
            this.serviceMocks,
        );

        return this.app.createContext(contextConfig);
    }

    getComponent() {
        return this.app.getComponent();
    }
}

export default AdminPortal;
