import services from '../../services';

// eslint-disable-next-line default-param-last
export default (config = {}, serviceMocks) => {
    const contextConfiguration = config;

    contextConfiguration.services = services(config.services, serviceMocks);

    return contextConfiguration;
};
