import client from '../../localization';

export default {
    id: '',
    version: '1.0.0',
    schema: '1.0.0',
    locale: 'en-US',
    configuration: {
        client,
    },
};
